.content {
  background-color: #e6f9fe;
  width: 45%;
  padding: 40px;
  text-align: left;
  box-sizing: border-box;
  animation: fadeInDown 400ms;
  order: 3;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.loadingContent {
  background-color: #e6f9fe;
  width: 45%;
  padding: 40px;
  text-align: left;
  height: 100vh;
  box-sizing: border-box;
  animation: fadeIn 1000ms;
  order: 3;
}

.spacer {
  width: 1;
  height: 100vh;
  display: flex;
  order: 3;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 768px) {
  .content,
  .loadingContent {
    width: 100%;
    padding: 15px;
    height: auto;
  }

  .spacer {
    display: none;
  }
}
