@import url(https://fonts.googleapis.com/css?family=Arimo:400,700&display=swap);
body {
  margin: 0;
  font-family: "Arimo", sans-serif;
}
/* Page background images */
#story,
#people {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/330ecef5d926b4fb2a9363d9e68e8462e173b3cc-1920x1080.jpg?fit=max");
}

#services {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/fd95652a22a0bed94b39c1b94a3ac46342530c14-1920x1080.jpg?fit=max");
}

#exhibition {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/8bcdbd29f09fcf9b82116e55178fbe338aff3777-1920x1080.jpg?fit=max");
}

#management,
#testimonials,
#home {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/82b074496bf23cc8a419b989b1f16e2bb91ff0e2-1920x1080.jpg?fit=max");
}

#online,
#secretariat,
#contact {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/b3355373625d507ef00589b85b3543c5b6e9cae4-1920x1080.jpg?fit=max");
}

#portfolio {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/52b42faceee8288828d14560d05758d969c8a697-1920x1180.jpg?fit=max");
}

#current-conferences {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/d059f59f3607e16af45ef349f4e43f9e48e2fa53-1920x1080.jpg?fit=max");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body {
  height: 100%;
}

.App {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/82b074496bf23cc8a419b989b1f16e2bb91ff0e2-1920x1080.jpg?fit=max");
  background-size: cover;
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.75);
  flex-wrap: wrap;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  align-content: flex-start;
}

.registerBtn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 24px;
  background: #332a87;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.registerBtn:hover {
  background: #231b73;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: fadeIn 1000ms;
          animation: fadeIn 1000ms;
}

h1 {
  font-weight: 600;
}
h2,
h3 {
  font-weight: 500;
}

.globalNotice {
  width: 100%;
  box-sizing: border-box;
  background: #ffbf00;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .registerBtn {
    position: relative;
    display: inline-block;
    padding: 12px 16px;
    font-size: 16px;
  }

  .App {
    height: 100vh;
  }
}

.Page_content__2COkr {
  background-color: #e6f9fe;
  width: 45%;
  padding: 40px;
  text-align: left;
  box-sizing: border-box;
  -webkit-animation: Page_fadeInDown__17eA6 400ms;
          animation: Page_fadeInDown__17eA6 400ms;
  order: 3;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.Page_loadingContent__1TLdI {
  background-color: #e6f9fe;
  width: 45%;
  padding: 40px;
  text-align: left;
  height: 100vh;
  box-sizing: border-box;
  -webkit-animation: Page_fadeIn__2krjI 1000ms;
          animation: Page_fadeIn__2krjI 1000ms;
  order: 3;
}

.Page_spacer__1bUt6 {
  width: 1;
  height: 100vh;
  display: flex;
  order: 3;
}

@-webkit-keyframes Page_fadeInDown__17eA6 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes Page_fadeInDown__17eA6 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@media screen and (max-width: 768px) {
  .Page_content__2COkr,
  .Page_loadingContent__1TLdI {
    width: 100%;
    padding: 15px;
    height: auto;
  }

  .Page_spacer__1bUt6 {
    display: none;
  }
}

.Alert_alert__1rkHi {
  font-size: 1em;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
}

.Alert_alert__1rkHi .icon {
  margin-right: 5px;
  fill: currentColor;
}

.Alert_alert__1rkHi.Alert_error__3geWi {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  color: #f5222d;
}

.Alert_alert__1rkHi.Alert_warning__2gWI4 {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
}

.Alert_alert__1rkHi.Alert_info__2Q_No {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
}

.Alert_alert__1rkHi.Alert_success__2FJwX {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
}

.Alert_message__MbsRU {
  color: #000;
  color: initial;
}

.Alert_banner__241yZ {
  display: flex;
}

.Skeleton_skeletonLoading__1g2To {
  position: relative;
}

.Skeleton_loadingTitle__1oiVY,
.Skeleton_loadingPara__3UZ9i {
  background: #b9dce6;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.Skeleton_loadingPara__3UZ9i {
  padding: 1px;
}

.Skeleton_loadingTitle__1oiVY {
  margin-bottom: 50px;
}

.Skeleton_loadingTitle__1oiVY:after,
.Skeleton_loadingPara__3UZ9i:after {
  content: "";
  top: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  width: 100%;
  height: 45px;
  position: absolute;
  z-index: 1;
  -webkit-animation: Skeleton_loadingStreak__k41Hf 1s infinite;
          animation: Skeleton_loadingStreak__k41Hf 1s infinite;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.3)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    to(rgba(125, 185, 232, 0))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
}

@-webkit-keyframes Skeleton_loadingStreak__k41Hf {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes Skeleton_loadingStreak__k41Hf {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@-webkit-keyframes Skeleton_fadeIn__1INKg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Skeleton_fadeIn__1INKg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Quote_quote__1xSut {
  display: block;
  position: relative;
  padding-left: 50px;
  margin: 50px 0;
}

.Quote_quote__1xSut:before {
  content: "“";
  font-size: 100px;
  font-family: Arial, sans-serif;
  position: absolute;
  left: 0;
  top: -10px;
  line-height: 1;
}

.Contact_contactRow__1M5Og {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.Contact_contactRow__1M5Og > div {
  width: 50%;
}

.Contact_contactBox__31vu5 {
  background-color: #f6c9cd;
  padding: 10px 25px;
  box-sizing: content-box;
}

.Contact_contactBox__31vu5 a {
  text-decoration: none;
  color: #428bca;
}

.Contact_fa__1ACz3 {
  font-size: 32px;
  margin-right: 15px;
}

@media screen and (max-width: 1200px) {
  .Contact_contactRow__1M5Og {
    flex-wrap: wrap;
  }
  .Contact_contactRow__1M5Og > div {
    width: 100%;
  }
  .Contact_contactBox__31vu5 {
    margin: 30px 0;
  }
}

.Conferences_button__cuZ1H {
  padding: 6px 10px;
  background: #332a87;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.Conferences_button__cuZ1H:hover {
  background: #231b73;
}

.Conferences_conf__1QMk9 {
  margin: 50px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #b6b6b6;
}

.Conferences_links__cmXyL {
  display: block;
}

.Conferences_title__eNOcE {
  font-size: 16px;
  font-weight: 600;
}

.Conferences_dates__312zX,
.Conferences_location__8lM-P {
  display: flex;
  align-items: center;
}

.People_profileImage__1YY_m {
  display: block;
  width: 100%;
}

.People_person__2keNW {
  box-sizing: border-box;
  position: relative;
  flex: 0 0 25%;
  margin: 0;
  padding: 5px;
}

.People_staffMembers__1CVn3 {
  display: flex;
  flex-wrap: wrap;
}

.People_person__2keNW:hover .People_nameOverlay__35kHs {
  display: block;
}

.People_nameOverlay__35kHs {
  display: none;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
}

.People_content__20CCo {
  display: flex;
  flex-wrap: wrap;
}

.People_info__1QMlU {
  display: block;
  flex: 1 1;
}

.People_pic__3613n {
  align-self: flex-start;
  margin-right: 25px;
  margin-bottom: 15px;
  max-width: 200px;
}

.People_personTitle__2850i {
  margin-top: 0;
  margin-bottom: 0;
}

.People_personJobTitle__qswPs {
  margin-top: 5px;
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
  .People_person__2keNW {
    flex: 0 0 33.3%;
  }
}

@media screen and (min-width: 1400px) {
  .People_person__2keNW {
    flex: 0 0 25%;
  }
}

@media screen and (max-width: 1400px) {
  .People_content__20CCo {
    flex-direction: column;
  }
}

@media screen and (max-width: 1000px) {
  .People_person__2keNW {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .People_person__2keNW {
    flex: 0 0 33.3%;
  }
}

.Loading_icon__3Yy7F {
  color: #fff;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: 35px;
  -webkit-animation-name: Loading_spin__27TzB;
          animation-name: Loading_spin__27TzB;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes Loading_spin__27TzB {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loading_spin__27TzB {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Sidebar_menu__1HeL0 {
  list-style-type: none;
  padding: 0;
}

.Sidebar_aside__3CT_o {
  order: 1;
  background: rgba(0, 0, 0, 0.75);
  width: 300px;
  padding-left: 25px;
  padding-top: 15px;
}

.Sidebar_parentButton__2pEir {
  display: block;
  width: 65px;
  height: 65px;
  box-sizing: border-box;
  background: none;
  border: none;
  cursor: pointer;
}

.Sidebar_icon__3dSow {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
}

.Sidebar_parentButton__2pEir:hover .Sidebar_icon__3dSow {
  -webkit-transform: scale(1.25, 1.25);
          transform: scale(1.25, 1.25);
}

.Sidebar_subMenu__3nLhq {
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  padding-left: 65px;
  box-sizing: border-box;
  list-style-type: none;
  min-height: 65px;
  display: none;
}

.Sidebar_parentItem__3dkeS {
  cursor: pointer;
  z-index: 1;
  position: relative;
  max-width: 65px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.Sidebar_parentItem__3dkeS:hover {
  max-width: 100%;
}

.Sidebar_parentItem__3dkeS:hover .Sidebar_subMenu__3nLhq {
  display: block;
}

.Sidebar_subItem__3Puyx {
  text-align: left;
  padding-left: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.Sidebar_subItemLink__X6Qrs {
  padding: 22px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  color: #fff;
  text-decoration: none;
}

.Sidebar_parentItem__3dkeS.Sidebar_loading__20nPd {
  overflow: hidden;
  max-width: 65px;
}

.Sidebar_parentButton__2pEir.Sidebar_loading__20nPd:after {
  content: "";
  top: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  width: 100%;
  height: 65px;
  position: absolute;
  z-index: 1;
  -webkit-animation: Sidebar_loadingStreak__g8Hdd 1s infinite;
          animation: Sidebar_loadingStreak__g8Hdd 1s infinite;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.3)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    to(rgba(125, 185, 232, 0))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
}

@-webkit-keyframes Sidebar_loadingStreak__g8Hdd {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes Sidebar_loadingStreak__g8Hdd {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.Sidebar_mobileToggleMenu__3Tkx1 {
  display: none;
}

@media screen and (max-width: 768px) {
  .Sidebar_mobileMenuOpen__25eKR {
    display: block;
  }

  .Sidebar_mobileMenuNotOpen__2aKWm {
    display: none;
  }

  .Sidebar_aside__3CT_o {
    padding: 15px;
    width: 100%;
    height: auto;
  }

  .Sidebar_mobileToggleMenu__3Tkx1 {
    display: flex;
    margin-left: auto;
    font-size: 16px;
    align-items: center;
    padding: 5px 15px;
    background: none;
    border: 2px solid white;
    color: #fff;
  }

  .Sidebar_menu__1HeL0 {
    width: 100%;
    box-sizing: border-box;
    margin-top: 80px;
  }

  .Sidebar_logo__1g0-c {
    width: 160px;
  }

  .Sidebar_items__301vZ {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  .Sidebar_subMenu__3nLhq {
    display: block;
    position: relative;
    top: -65px;
  }

  .Sidebar_parentItem__3dkeS {
    max-width: 100%;
    margin-top: -67px;
  }
}

.Footer_footer__1w0lV {
  position: absolute;
  order: 2;
  bottom: 15px;
  left: 25px;
}

.Footer_footer__1w0lV a,
.Footer_footer__1w0lV p {
  color: #fff;
  text-decoration: none;
  font-size: 0.9em;
}

.Footer_footer__1w0lV a:hover {
  text-decoration: underline;
}

.Footer_socials__e39nQ {
  padding: 0;
  list-style-type: none;
  text-align: left;
}

.Footer_socials__e39nQ > li {
  display: inline-block;
  margin-right: 12px;
}

.Footer_socials__e39nQ > li:hover {
  -webkit-filter: brightness(0.75);
          filter: brightness(0.75);
}

.Footer_contactInfo__2LWFr {
  background: #da3f8d;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 15px;
  font-size: 18px;
}

.Footer_contactInfo__2LWFr p {
  margin: 2px 0;
}

@media screen and (max-width: 768px) {
  .Footer_footer__1w0lV {
    display: flex;
    flex-direction: column;
    position: relative;
    order: 3;
    background: black;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    padding: 15px 0;
  }

  .Footer_socials__e39nQ {
    text-align: center;
    order: 1;
    margin: 5px 0;
  }

  .Footer_contactInfo__2LWFr {
    position: relative;
    background: black;
    order: 2;
    font-size: 16px;
  }

  .Footer_policies__1MPzM {
    order: 3;
    margin-bottom: 8px;
    margin-top: 2px;
  }

  .Footer_copyright__2OWH4 {
    order: 4;
    margin-bottom: 8px;
    margin-top: 2px;
  }
}

