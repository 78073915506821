.quote {
  display: block;
  position: relative;
  padding-left: 50px;
  margin: 50px 0;
}

.quote:before {
  content: "“";
  font-size: 100px;
  font-family: Arial, sans-serif;
  position: absolute;
  left: 0;
  top: -10px;
  line-height: 1;
}
