.button {
  padding: 6px 10px;
  background: #332a87;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.button:hover {
  background: #231b73;
}

.conf {
  margin: 50px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #b6b6b6;
}

.links {
  display: block;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.dates,
.location {
  display: flex;
  align-items: center;
}
