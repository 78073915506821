.icon {
  color: #fff;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: 35px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
