.skeletonLoading {
  position: relative;
}

.loadingTitle,
.loadingPara {
  background: #b9dce6;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.loadingPara {
  padding: 1px;
}

.loadingTitle {
  margin-bottom: 50px;
}

.loadingTitle:after,
.loadingPara:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 45px;
  position: absolute;
  z-index: 1;
  animation: loadingStreak 1s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
}

@keyframes loadingStreak {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
