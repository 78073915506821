.footer {
  position: absolute;
  order: 2;
  bottom: 15px;
  left: 25px;
}

.footer a,
.footer p {
  color: #fff;
  text-decoration: none;
  font-size: 0.9em;
}

.footer a:hover {
  text-decoration: underline;
}

.socials {
  padding: 0;
  list-style-type: none;
  text-align: left;
}

.socials > li {
  display: inline-block;
  margin-right: 12px;
}

.socials > li:hover {
  filter: brightness(0.75);
}

.contactInfo {
  background: #da3f8d;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 15px;
  font-size: 18px;
}

.contactInfo p {
  margin: 2px 0;
}

@media screen and (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    position: relative;
    order: 3;
    background: black;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    padding: 15px 0;
  }

  .socials {
    text-align: center;
    order: 1;
    margin: 5px 0;
  }

  .contactInfo {
    position: relative;
    background: black;
    order: 2;
    font-size: 16px;
  }

  .policies {
    order: 3;
    margin-bottom: 8px;
    margin-top: 2px;
  }

  .copyright {
    order: 4;
    margin-bottom: 8px;
    margin-top: 2px;
  }
}
