.menu {
  list-style-type: none;
  padding: 0;
}

.aside {
  order: 1;
  background: rgba(0, 0, 0, 0.75);
  width: 300px;
  padding-left: 25px;
  padding-top: 15px;
}

.parentButton {
  display: block;
  width: 65px;
  height: 65px;
  box-sizing: border-box;
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;
  transition: 200ms all ease-in-out;
}

.parentButton:hover .icon {
  transform: scale(1.25, 1.25);
}

.subMenu {
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  padding-left: 65px;
  box-sizing: border-box;
  list-style-type: none;
  min-height: 65px;
  display: none;
}

.parentItem {
  cursor: pointer;
  z-index: 1;
  position: relative;
  max-width: 65px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.parentItem:hover {
  max-width: 100%;
}

.parentItem:hover .subMenu {
  display: block;
}

.subItem {
  text-align: left;
  padding-left: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.subItemLink {
  padding: 22px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  color: #fff;
  text-decoration: none;
}

.parentItem.loading {
  overflow: hidden;
  max-width: 65px;
}

.parentButton.loading:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 65px;
  position: absolute;
  z-index: 1;
  animation: loadingStreak 1s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
}

@keyframes loadingStreak {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.mobileToggleMenu {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileMenuOpen {
    display: block;
  }

  .mobileMenuNotOpen {
    display: none;
  }

  .aside {
    padding: 15px;
    width: 100%;
    height: auto;
  }

  .mobileToggleMenu {
    display: flex;
    margin-left: auto;
    font-size: 16px;
    align-items: center;
    padding: 5px 15px;
    background: none;
    border: 2px solid white;
    color: #fff;
  }

  .menu {
    width: 100%;
    box-sizing: border-box;
    margin-top: 80px;
  }

  .logo {
    width: 160px;
  }

  .items {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  .subMenu {
    display: block;
    position: relative;
    top: -65px;
  }

  .parentItem {
    max-width: 100%;
    margin-top: -67px;
  }
}
