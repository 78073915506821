@import url("https://fonts.googleapis.com/css?family=Arimo:400,700&display=swap");

body {
  margin: 0;
  font-family: "Arimo", sans-serif;
}
/* Page background images */
#story,
#people {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/330ecef5d926b4fb2a9363d9e68e8462e173b3cc-1920x1080.jpg?fit=max");
}

#services {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/fd95652a22a0bed94b39c1b94a3ac46342530c14-1920x1080.jpg?fit=max");
}

#exhibition {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/8bcdbd29f09fcf9b82116e55178fbe338aff3777-1920x1080.jpg?fit=max");
}

#management,
#testimonials,
#home {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/82b074496bf23cc8a419b989b1f16e2bb91ff0e2-1920x1080.jpg?fit=max");
}

#online,
#secretariat,
#contact {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/b3355373625d507ef00589b85b3543c5b6e9cae4-1920x1080.jpg?fit=max");
}

#portfolio {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/52b42faceee8288828d14560d05758d969c8a697-1920x1180.jpg?fit=max");
}

#current-conferences {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/d059f59f3607e16af45ef349f4e43f9e48e2fa53-1920x1080.jpg?fit=max");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body {
  height: 100%;
}

.App {
  background-image: url("https://cdn.sanity.io/images/vkzkzmt1/production/82b074496bf23cc8a419b989b1f16e2bb91ff0e2-1920x1080.jpg?fit=max");
  background-size: cover;
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.75);
  flex-wrap: wrap;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  align-content: flex-start;
}

.registerBtn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 24px;
  background: #332a87;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.registerBtn:hover {
  background: #231b73;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  width: 100%;
  height: 100%;
  animation: fadeIn 1000ms;
}

h1 {
  font-weight: 600;
}
h2,
h3 {
  font-weight: 500;
}

.globalNotice {
  width: 100%;
  box-sizing: border-box;
  background: #ffbf00;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .registerBtn {
    position: relative;
    display: inline-block;
    padding: 12px 16px;
    font-size: 16px;
  }

  .App {
    height: 100vh;
  }
}
