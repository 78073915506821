.profileImage {
  display: block;
  width: 100%;
}

.person {
  box-sizing: border-box;
  position: relative;
  flex: 0 0 25%;
  margin: 0;
  padding: 5px;
}

.staffMembers {
  display: flex;
  flex-wrap: wrap;
}

.person:hover .nameOverlay {
  display: block;
}

.nameOverlay {
  display: none;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.info {
  display: block;
  flex: 1;
}

.pic {
  align-self: flex-start;
  margin-right: 25px;
  margin-bottom: 15px;
  max-width: 200px;
}

.personTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.personJobTitle {
  margin-top: 5px;
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
  .person {
    flex: 0 0 33.3%;
  }
}

@media screen and (min-width: 1400px) {
  .person {
    flex: 0 0 25%;
  }
}

@media screen and (max-width: 1400px) {
  .content {
    flex-direction: column;
  }
}

@media screen and (max-width: 1000px) {
  .person {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .person {
    flex: 0 0 33.3%;
  }
}
