.alert {
  font-size: 1em;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
}

.alert :global(.icon) {
  margin-right: 5px;
  fill: currentColor;
}

.alert.error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  color: #f5222d;
}

.alert.warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
}

.alert.info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
}

.alert.success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
}

.message {
  color: initial;
}

.banner {
  display: flex;
}
